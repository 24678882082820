import React from 'react';
import Default from '../../../layouts/default';
import SEO from 'components/seo';
import Button from '../../../components/_POC/Button';
import { REQUEST_DEMO_ANCHOR, SIGNUP_URL } from '../../../core/links';
import ApiSolutionSlice1 from '../../../images/solutions/API/ApiSolutionSlice1.svg';
import ApiSolutionSlice2 from '../../../images/solutions/API/ApiSolutionSlice6.png';
import ApiSolutionSlice3 from '../../../images/solutions/API/ApiSolutionSlice3.png';
import ApiSolutionSlice4 from '../../../images/solutions/API/ApiSolutionSlice4.png';
import ApiSolutionSlice5 from '../../../images/solutions/API/ApiSolutionSlice5.png';
import * as SolutionAPIStyle from './style.module.scss';
import images from '../../../images/homepage';
import { Carousel } from 'react-bootstrap';
import './CarouselStyle.scss';
import Testimonial from '../../../components/_POC/Testimonial';

/*
 Quick hardcoded page, no connection to contentful.
 Quick code but this will disappear with the word-press migration.
 */

const APIDiscovery: React.FC = () => {
  const seo = {
    title: 'Discover every API in your attack surface',
    description:
      'API Discovery Powered by HawkAI provides AppSec teams with an updated view of all APIs, prioritizes critical ones for security testing, and ensures ongoing visibility of attack surface coverage.',
    socialMediaThumb: {
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/nx13ojx82pll/4QSRHg3YDzd9UPP6SQwTkp/e9c12e1cb0a005057cef464cc2a28e5b/HawkAI_SEO.jpg?w=300&h=160&q=90&fm=webp 300w,\nhttps://images.ctfassets.net/nx13ojx82pll/4QSRHg3YDzd9UPP6SQwTkp/e9c12e1cb0a005057cef464cc2a28e5b/HawkAI_SEO.jpg?w=600&h=320&q=90&fm=webp 600w,\nhttps://images.ctfassets.net/nx13ojx82pll/4QSRHg3YDzd9UPP6SQwTkp/e9c12e1cb0a005057cef464cc2a28e5b/HawkAI_SEO.jpg?w=1200&h=640&q=90&fm=webp 1200w',
              sizes: '(min-width: 1200px) 1200px, 100vw',
              type: 'image/webp',
            },
          ],
          fallback: {
            src: 'https://images.ctfassets.net/nx13ojx82pll/4QSRHg3YDzd9UPP6SQwTkp/e9c12e1cb0a005057cef464cc2a28e5b/HawkAI_SEO.jpg?w=1200&h=640&q=90&fm=png',
            srcSet:
              'https://images.ctfassets.net/nx13ojx82pll/4QSRHg3YDzd9UPP6SQwTkp/e9c12e1cb0a005057cef464cc2a28e5b/HawkAI_SEO.jpg?w=300&h=160&q=90&fm=png 300w,\nhttps://images.ctfassets.net/nx13ojx82pll/4QSRHg3YDzd9UPP6SQwTkp/e9c12e1cb0a005057cef464cc2a28e5b/HawkAI_SEO.jpg?w=600&h=320&q=90&fm=png 600w,\nhttps://images.ctfassets.net/nx13ojx82pll/4QSRHg3YDzd9UPP6SQwTkp/e9c12e1cb0a005057cef464cc2a28e5b/HawkAI_SEO.jpg?w=1200&h=640&q=90&fm=png 1200w',
            sizes: '(min-width: 1200px) 1200px, 100vw',
          },
        },
        layout: 'constrained',
        width: 1200,
        height: 640,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAMAAABI111xAAAB71BMVEUkIjwkITskIDolIDolITsmIjwmIDsqHj0uHkI0Hkc6Hk0/HlJDH1YjJ0EjJkAjJT8kIz1DQFZiX3I9OlFDP1ZPS2BtantycIFPSWAqID8wIUY2Ik08IlNBI1lFI14hMEghLkciLEUiKUIjJT4oJD0wLEUmIT0rJEQxJk04KFY/KV1FKmRKKmkeO1IeOlAfN00gM0ohLUVaXXCMjJmAf45XVmqNjZp4d4eQkJ1VV2p6fI1iY30yMFk6MmNCM2tJM3JPMncaSl4bR1ocQ1cdQFQeO08fNksgM0kgM0gfNUogOE0hOVAlO1YrPV4zPmc7PnBEPXdNPX9VPYcWW2wZLjwgIC4jIzEiIjAbHCoZGSccHCobHiwXLDglKkRdSJoSbnwYNEElKjgvMD4mJzUhIjAiIzIgIS8jJDIeKjcRUFgjO1RlU64NgYsfNUMsLTsoKTckJTMfHy0qKzopKjglJjUjIzIoKTglJzUrKkZuXsEJkpoYMj8pKjcmKDYnKTcpKTYpKTctLUt2Z9MGoKUWMj4jIi8zMDorKzgpKjkfIC4wLTgqKzklJjQyLTk5NDsrKzkuLk1+beEEqawWNEAhIy8xPkQ1OT8xOD0zPUIeHy0qLjcuPEM3P0IsMTwlJzMgIS4yPkQ5RUcwNz8uLU2Ecepm27b1AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH6AMBERIh4KaHMAAAAIJJREFUCB0FwbFOwmAUBtDvlDtAw18ScDHGkDCSsPmkvhwsxsXBwQU6kCZqOUeCMCdJB1TDtWsAoLbYfex8JskBcAIAQD0BEgBqi5g7SZKAWgPGJEkaVHPvufdrxuG2MWWpBpNmOB//5/721//+vCzUyp7v57dx8PV6aWqDdwCAaekBRLcaWiOYXRkAAAAASUVORK5CYII=',
        },
      },
    },
    canonicalUrl: '',
    noindex: false,
    nofollow: false,
  };

  return (
    <Default>
      <SEO data={seo} />
      <div id="apiDiscovery" className={`mt-52 container`}>
        <div className={`px-4 ${SolutionAPIStyle.modernDASTWrapper}`}>
          <div className={`flex flex-col justify-center items-center py-16 homepage-hero ${SolutionAPIStyle.hero}`}>
            <div className={SolutionAPIStyle.heroLeft}>
              <h1>
                <span className={SolutionAPIStyle.heroTitle}>API Discovery</span>
                <br className="d-flex d-lg-none" />
                <span className={SolutionAPIStyle.heroTitleSplit}>Powered by HawkAI</span>
              </h1>
              <p>
                API Discovery Powered by HawkAI automatically prioritizes your apps and APIs for security testing and
                keeps you up to date on your attack surface coverage as code changes.
              </p>
              <div className={SolutionAPIStyle.heroButtonContainer}>
                <Button
                  type="button"
                  variant="button-simple"
                  label="Book a Demo"
                  style="btn-primary"
                  disabled={false}
                  size="header"
                  url={REQUEST_DEMO_ANCHOR}
                />
                <Button
                  type="button"
                  variant="button-simple"
                  label="Create a Free Account"
                  style="outline"
                  disabled={false}
                  size="header"
                  url={SIGNUP_URL}
                />
              </div>
            </div>
            <div className={SolutionAPIStyle.heroRight}>
              <div className="text-lg-right text-center flex flex-col col-lg-6 align-items-stretch">
                <div className="align-items-middle w-auto mt-4 text-center">
                  <div className={`${SolutionAPIStyle.layout} embed-responsive embed-responsive-16by9`}>
                    <iframe
                      src="https://fast.wistia.net/embed/iframe/gqxg4121ra"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                      allowFullScreen
                      height="100%"
                      width="100%"
                      className="border-0 embed-responsive-item"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={SolutionAPIStyle.contentBlock}>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <div className={SolutionAPIStyle.contentWrapper642Dense}>
                <span className={SolutionAPIStyle.securityStartsWithTitle}>
                  Security Starts with
                  <br />
                </span>
                <span className={SolutionAPIStyle.securityStartsWithTitleSplit}>VISIBILITY</span>

                <div className={SolutionAPIStyle.securityStartsWithSummary}>
                  We discover thousands of APIs, often untested or unknown, in any GitHub or Azure DevOps repository.
                  Identify your attack surface and determine which apps need security testing, allowing engineers to
                  code confidently while security teams rest easy at night.
                </div>

                <div className={SolutionAPIStyle.securityStartsWithActions}>
                  <a
                    href="/blog/announcing-api-discovery-powered-by-hawkai/"
                    className={SolutionAPIStyle.ReadBlogButton}
                  >
                    Read the Blog
                  </a>
                </div>
              </div>
              <div className={`${SolutionAPIStyle.basicImageWrapper} d-none d-lg-flex`}>
                <img className={SolutionAPIStyle.img418} src={ApiSolutionSlice1} alt={'description'} />
              </div>
            </div>
          </div>

          <div className={SolutionAPIStyle.pb128}>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <div className={SolutionAPIStyle.contentWrapper700Left}>
                <span className={SolutionAPIStyle.contentTitle}>Automatic Prioritization of which APIs to Scan</span>

                <div className={SolutionAPIStyle.contentSummary}>
                  Automatically discover the running applications and APIs in your attack surface and leverage AI
                  insights to prioritize the critical ones for security testing. Immediately configure discovered APIs
                  and applications for automated security testing in your software development lifecycle.
                </div>

                <div>
                  <Button
                    type="button"
                    variant="button-simple"
                    label="Book a Demo"
                    style="btn-primary"
                    disabled={false}
                    size="header"
                    url={REQUEST_DEMO_ANCHOR}
                  />
                </div>
              </div>
              <div className="flex-grow-1 d-none d-lg-flex">
                <img className={SolutionAPIStyle.img300} src={ApiSolutionSlice3} alt={'description'} />
              </div>
            </div>
          </div>

          <div className={SolutionAPIStyle.pb128}>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <div className="flex-grow-1  d-none d-lg-flex">
                <img className={SolutionAPIStyle.img300} src={ApiSolutionSlice4} alt={'description'} />
              </div>
              <div className={SolutionAPIStyle.contentWrapper700Right}>
                <span className={SolutionAPIStyle.contentTitle}>Maintain Continuous Oversight</span>

                <div className={SolutionAPIStyle.contentSummary}>
                  Gain a comprehensive, up-to-date view of all APIs in your attack surface and keep a close eye on
                  coverage and development activity.
                </div>

                <div>
                  <Button
                    type="button"
                    variant="button-simple"
                    label="Read the Docs"
                    style="btn-primary"
                    disabled={false}
                    size="header"
                    url="https://docs.stackhawk.com/web-app/api-discovery.html"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={SolutionAPIStyle.pb128}>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <div className={SolutionAPIStyle.contentWrapper700Left}>
                <span className={SolutionAPIStyle.contentTitle}>Leverage Valuable Code Insights</span>

                <div className={SolutionAPIStyle.contentSummary}>
                  Foster collaboration with development teams by identifying where APIs live in the code base and the
                  last developer who committed code to streamline bringing assets under security testing.
                </div>

                <div>
                  <Button
                    type="button"
                    variant="button-simple"
                    label="Book a Demo"
                    style="btn-primary"
                    disabled={false}
                    size="header"
                    url={REQUEST_DEMO_ANCHOR}
                  />
                </div>
              </div>
              <div className="flex-grow-1 d-none d-lg-flex">
                <img className={SolutionAPIStyle.img300} src={ApiSolutionSlice5} alt={'description'} />
              </div>
            </div>
          </div>

          <section className={`api-discovery  banner-block bg-transparent`}>
            <div className="">
              <h2 className={`${SolutionAPIStyle.whatCustomersAreSaying} text-center`}>WHAT CUSTOMERS ARE SAYING</h2>
            </div>
            <div className={SolutionAPIStyle.contentCarousel}>
              <Carousel>
                <Carousel.Item>
                  <Testimonial
                    childContentfulContentComponentBlockDescriptionTextNode={{
                      description:
                        'Ensuring no rogue APIs are left untested is a big concern for us. API Discovery can really help improve our visibility and reporting capabilities.',
                    }}
                    childContentfulContentComponentBlockFooterTextNode={{
                      footer: '— Staff Security Engineer, Cloud Storage Provider',
                    }}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <Testimonial
                    childContentfulContentComponentBlockDescriptionTextNode={{
                      description:
                        'Having comprehensive API discovery will bridge the gap between security and development teams, facilitating better collaboration.',
                    }}
                    childContentfulContentComponentBlockFooterTextNode={{ footer: '— Change.org' }}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <Testimonial
                    childContentfulContentComponentBlockDescriptionTextNode={{
                      description:
                        'Identifying all APIs and managing them effectively has been a challenge. This feature can automate and improve our process.',
                    }}
                    childContentfulContentComponentBlockFooterTextNode={{ footer: '— CACU' }}
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </section>

          <div className={SolutionAPIStyle.contentBlock}>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <div className={SolutionAPIStyle.contentWrapper642}>
                <span className={SolutionAPIStyle.basicTitle}>
                  Available with your
                  <br />
                </span>
                <span className={SolutionAPIStyle.basicTitle}>Enterprise plan</span>

                <div className={SolutionAPIStyle.basicSummary}>
                  API Discovery Powered by HawkAI is available for StackHawk customers on an Enterprise plan. Start a
                  trial or book a demo to try it free for 14 days.
                </div>

                <div>
                  <div className={SolutionAPIStyle.startForFree}>
                    <a href={SIGNUP_URL} className={SolutionAPIStyle.startForFreeLink}>
                      Start Your Free Trial
                    </a>
                  </div>
                </div>
              </div>
              <div className={`${SolutionAPIStyle.complexImageWrapper}  d-none d-lg-flex`}>
                <img className={SolutionAPIStyle.img380} src={ApiSolutionSlice2} alt={'description'} />
              </div>
            </div>
          </div>

          <div className={SolutionAPIStyle.pb128}>
            <div className={SolutionAPIStyle.closeBlockTitle}>An AI Experience You Can Trust</div>
            <div className={SolutionAPIStyle.closeBlockSummary}>
              We will never send customer data or code to third-parties or use customer data to train LLMs. All data is
              processed internally within StackHawk's secure systems and with our selected AI vendor.
            </div>

            <div className="text-center py-4">
              <a href="/blog/how-we-built-hawkai-to-protect-your-data/" className={SolutionAPIStyle.privacyButton}>
                Read our Privacy Guidelines{' '}
                <em>
                  <img src={images.ArrowRightIcon} alt="Right Arrow Icon" />
                </em>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Default>
  );
};

export default APIDiscovery;
